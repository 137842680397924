"use client";

import client, { paths as Paths } from "@open/sdk";
import { getBaseUrl } from "data/instance";
import { createSafeContext } from "lib/createSafeContext";
import createOpenApiReactQueryClient, {
  OpenapiQueryClient,
} from "openapi-react-query";
import { ReactNode, useMemo } from "react";

export type ApiClient = ReturnType<typeof client>;

function formatAccessToken(accessToken: string) {
  return `Bearer ${accessToken}`;
}

const [SafeApiProvider, useApi] = createSafeContext<{
  apiClient: ApiClient;
  /**
   * @deprecated use $query instead
   */
  query: OpenapiQueryClient<Paths>;
  $query: OpenapiQueryClient<Paths>;
}>("Cannot use the API context outside the ApiProvider");

export function ApiProvider({
  children,
  accessToken,
}: { children: ReactNode; accessToken: string }) {
  const apiClient = useMemo(() => {
    return client({
      baseUrl: getBaseUrl(),
      async onRequest({ request }) {
        request.headers.set("Authorization", formatAccessToken(accessToken));
      },
    });
  }, [accessToken]);

  const $query = useMemo(
    () => createOpenApiReactQueryClient(apiClient),
    [apiClient],
  );

  return (
    <SafeApiProvider value={{ apiClient, $query, query: $query }}>
      {children}
    </SafeApiProvider>
  );
}

export { useApi };
