import client from "@open/sdk";
import { authOptions } from "lib/auth-options";
import _ from "lodash";
import { getServerSession } from "next-auth";
import { env } from "next-runtime-env";

export function getBaseUrl() {
  let url =
    (env("NEXT_PUBLIC_HEMIDAL_URL") || process.env.NEXT_PUBLIC_HEMIDAL_URL) ??
    "";
  return url.replace("/backend", "");
}

export const serverApiClient = client({
  baseUrl: getBaseUrl(),
  async onRequest({ params, request }) {
    const session = await getServerSession(authOptions);
    if (session?.access_token) {
      request.headers.set("Authorization", `Bearer ${session.access_token}`);
    }
  },
});
