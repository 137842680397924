interface HubSpotProfile extends Record<string, any> {
  // TODO: figure out additional fields, for now using
  // https://legacydocs.hubspot.com/docs/methods/oauth2/get-access-token-information

  user: string
  user_id: string

  hub_domain: string
  hub_id: string
}


export interface OAuthUser {
  id: string;
  name: string;
  email: string;
  image: string | null;
  provider: string;
  accessToken: string;
  idToken: string;
  avatar_url?: string;
}

interface OAuthProvider<T = any> {
  getUser(profile: T): OAuthUser;
}

export class GitHubProviderStrategy implements OAuthProvider {
  getUser(profile: any): OAuthUser {
    return {
      id: profile.user.id,
      name: profile.user.name,
      email: profile.user.email,
      image: profile.user.image,
      provider: "github",
      accessToken: profile.account.access_token,
      avatar_url: profile.profile.avatar_url,
      idToken: "",
    };
  }
}

export class GoogleProviderStrategy implements OAuthProvider {
  getUser(profile: any): OAuthUser {
    return {
      id: profile.profile.sub,
      name: profile.profile.name,
      email: profile.profile.email,
      image: profile.user.image,
      provider: "google",
      accessToken: profile.account.access_token,
      idToken: profile.account.id_token,
    };
  }
}
export class HubspotProviderStrategy implements OAuthProvider<HubSpotProfile> {
  getUser(profile: HubSpotProfile): OAuthUser {
    return {
      provider: "hubspot",
      id: profile.user_id,
      name: profile.user,
      email: profile.user,
      accessToken: "",
      image: "",
      idToken: "",
    }
  }
}
export function getOAuthUser(profile: any, provider: OAuthProvider): OAuthUser {
  return provider.getUser(profile);
}
