"use client";
import {
  Tooltip as OriginalTooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@open/hoose/ui";
import React, { ComponentPropsWithoutRef } from "react";

export function Tooltip({
  children,
  content,
  delay = 0,
  ...contentProps
}: {
  children: React.ReactNode;
  content?: React.ReactNode;
  delay?: number;
} & Omit<ComponentPropsWithoutRef<typeof TooltipContent>, "content">) {
  if (!content) return children;

  return (
    <TooltipProvider delayDuration={delay}>
      <OriginalTooltip>
        <TooltipTrigger asChild>{children}</TooltipTrigger>
        {content && (
          <TooltipContent {...contentProps}>{content}</TooltipContent>
        )}
      </OriginalTooltip>
    </TooltipProvider>
  );
}
