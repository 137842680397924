// src/index.ts
import createClient from "openapi-fetch";
var noop = () => {
};
var basicClient = (options) => {
  var _a;
  let client = createClient({
    baseUrl: options.baseUrl
  });
  let middlewares = {
    onRequest: options.onRequest,
    onResponse: options.onResponse,
    onError: (_a = options.onError) != null ? _a : noop
  };
  client.use(middlewares);
  return client;
};
var src_default = basicClient;
export {
  src_default as default
};
