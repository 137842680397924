"use client";

import { useShortcutsRegistry } from "app/_data-providers/ClientifiedHotkeysProvider";
import { Shortcut, ShortcutValueU } from "enums/shortcut.enum";
import { DependencyList, ReactElement, ReactNode, useEffect } from "react";
import {
  HotkeyCallback,
  Options as HotkeysOptions,
  useHotkeys,
} from "react-hotkeys-hook";

/**
 * If you want the shortcut to work even if an input is focused, use composite shortcuts (special-key + character)
 */
export function useHotkey(
  keys: (typeof Shortcut)[keyof typeof Shortcut][],
  callback: HotkeyCallback,
  {
    icon,
    ...options
  }: HotkeysOptions & { description: string; icon: ReactElement },
  deps?: DependencyList,
) {
  useHotkeys(
    keys,
    callback,
    {
      preventDefault: true,
      // enable on form tags only if the shortcut contains special characters (ctrl, meta, shift, etc.) which will not contradict with inputting text inside the form tag
      enableOnFormTags: keys.every((k) => k.includes("+")),
      enableOnContentEditable: keys.every((k) => k.includes("+")),
      ...options,
    },
    deps,
  );

  // register the callback in the registry
  const { addToRegistry, removeFromRegistry } = useShortcutsRegistry();

  useEffect(() => {
    keys.forEach((k) => {
      addToRegistry(k, { callback, icon: icon });
    });

    return () => {
      keys.forEach((key) => {
        removeFromRegistry(key);
      });
    };
  }, [callback]);
}
