"use client";
import { createSafeContext } from "@open/hoose/utils/create-safe-context";
import { components } from "@open/sdk";
import { cloneDeep } from "lodash";
import React from "react";
import { mutate } from "swr";

const revalidateOrgData = (orgId: string) => mutate(orgId);

const [SafeOrgDataProvider, useOrgData] =
  createSafeContext<components["schemas"]["ChatbotDto"]>("Org context");

function OrgProvider({
  children,
  data,
}: {
  children: React.ReactNode;
  data: components["schemas"]["ChatbotDto"];
}) {
  if (!data) return null;

  return (
    <SafeOrgDataProvider value={cloneDeep(data)}>
      {children}
    </SafeOrgDataProvider>
  );
}

export { OrgProvider, useOrgData, revalidateOrgData };
